<template>
    <div>
         <NavBar>
                <img src="~assets/image/home/left.png" alt="" slot="left" class="left">
                <span slot="center">补充建议</span>
            </NavBar>
        <div class="inputDiv">
            <div class="tagDiv">
                <span class="firstP">猜你想说</span>
                <span class="tagP" v-for="item of tagLabelArr" :key="item.id" @click="clickTag">{{item.label}}</span>
                
            </div>
            <textarea name="" class="mesText" v-model="suggest" placeholder="请在此填写意见"></textarea>
        </div>
        <div class="btnDiv">
                <button class="btn" @click="toSaveMessage">同意并提交</button>
                <button class="btn_reject" v-if="btnRufuseFlag" @click="rejectClick">驳回</button>
        </div>
        
        <van-steps direction="vertical" active-color="#239AF9">
            <van-step v-for="(item,index) of suggestArr" :key="item.id">
                <div class="titleDiv">
                    <p class="nameP" :style="index==0?activeText:''">{{item.type == 1?'自评':(item.type == 2 ?'副主管评':(item.type == 3?'主管评':''))}}</p>
                    <p class="timeP">{{item.createTime}}</p>
                </div>
                <p class="remark" :style="index==0?activeText:''">评语:{{item.remark}}</p>
                <p class="state" :style="index== 0?activeState:''">{{item.refuseFlag == 1 ? '驳回':(item.refuseFlag == 0 ? '通过' : '')}}</p>
            </van-step>
           
        </van-steps>

        
        <!-- <StaffSuggestMessage :dataArr="suggestArr"></StaffSuggestMessage> -->
        
    </div>
</template>
<script>
// import StaffSuggestMessage from 'components/staffSuggest/StaffSuggestMessage.vue'
import NavBar from 'components/navBar/NavBar.vue'
import {getRemark,saveRemark,refuseRemark,getRemarkTag} from '../../network/normal'

import { Step, Steps } from 'vant';
import Vue from 'vue';

export default {
    name:'SuggestMessage',
    components:{
        NavBar
    },
    data(){
        return {
            suggestArr:[],
            suggest:'',
            btnRufuseFlag:false,
            tagLabelArr:[],
            activeState:{
                color:'#239AF9'
            },
            activeText:{
                color: '#333333'
            }
        }
    },
    created(){
        Vue.use(Step).use(Steps);

        let taskId = localStorage.getItem('taskId')
        getRemark(taskId).then((res)=>{
            if(res.success){
                this.suggestArr = res.data;
                this.btnRufuseFlag = res.extra.btnRufuseFlag;
            }else{
                this.$toast.fail(res.msg);
            }
        })
        getRemarkTag().then((res)=>{
            if(res.success){
                this.tagLabelArr = res.data.list;
            }else{
                this.$toast.fail(res.msg);
            }
        })
    },
    methods:{
        toSaveMessage(){
             let taskId = localStorage.getItem('taskId')
             if(this.suggest){
                 this.$toast.loading({
                    message: '努力加载中...',
                    forbidClick: false,
                    duration:0
                 });
                saveRemark(this.suggest,taskId).then((res)=>{
                     this.$toast.clear();
                    if(res.success){
                       this.$toast.success(res.msg);
                       this.$router.go(-2)
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
             }else{
                 this.$toast.fail('请填写建议后再进行提交');
             }
           
        },
        rejectClick(){
            let taskId = localStorage.getItem('taskId')
           
             if(this.suggest){
                 this.$toast.loading({
                    message: '努力加载中...',
                    forbidClick: false,
                    duration:0
                 });
               refuseRemark(this.suggest,taskId).then((res)=>{
                   this.$toast.clear();
                     if(res.success){
                       this.$toast.success(res.msg);
                        this.$router.go(-2)
                    }else{
                        this.$toast.fail(res.msg);
                    }
                })
             }else{
                 this.$toast.fail('请填写意见后进行驳回');
             }
                 
             
        },
        clickTag(e){
            let label = e.target.innerText;
            label+= ' ';
            this.suggest += label;
        }
    },
 mounted(){
    document.querySelector('body').setAttribute('style','background-color:#F4F4F4')
    
  },
  beforeDestroy(){
    document.querySelector('body').removeAttribute('style')
  },
}
</script>
<style  scoped>
.left{
        margin-left: 24px;
        width: 23px;
        height: 41px; 
}
.inputDiv{
     width: 92%;
     background: #ffffff;
    border-radius: 10px;
     margin: 130px auto;
    overflow: hidden;
    margin-bottom: 0px;
}
.tagDiv{
    margin: 25px 29px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}
.tagDiv span{
    font-size: 20px;
    font-weight: 400;
    color: #858383;
    line-height: 36px;
}
.firstP{
    margin-right: 22px;
}
.tagP{
    margin-right: 22px;
    margin-bottom: 14px;
    padding: 7px 18px;
    border: 1px solid #D6D6D6;
    border-radius: 18px;

}
.mesText{
    width: 92%;
    margin: 10px auto;
    display: block;
    border: none;
    outline: none;
    height: 120px;

}
.btnDiv{
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn{
    width: 250px;
    background: linear-gradient(-90deg, #2998F8, #08A3FE);
    border-radius: 32px;
    font-size: 30px;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 63px;
    border: none;
    display: block;
   

}
.btn_reject{
     margin-left: 100px;
    width: 177px;
    border: 1px solid #239AF9;
    border-radius: 32px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #239AF9;
    line-height: 63px;
}
.titleDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nameP{
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    padding-top: 29px;
    padding-left: 32px;
}
.timeP{

    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
     padding-top: 35px;
  padding-right: 37px;
}
  .remark{
    
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    margin: 25px 36px 44px 33px;
    padding-bottom: 44px;
    line-height: 40px;
  }  
  .state{
      position: absolute;
      bottom: 20px;
      right: 36px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;

  }
  .van-step--vertical{
      padding: 0;
  }
  .van-steps{
      margin-left: 30px;
      margin-right: 30px;
  }
   
</style>